export default () => {
  const forms = document.querySelectorAll('form')
  if (!forms.length) {
    return false
  }
  const request = new XMLHttpRequest()
  request.open('GET', '/actions/blitz/csrf/token/', true)
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'CRAFT_CSRF_TOKEN')
      input.setAttribute('value', this.response)

      for (let i = 0; i < forms.length; i++) {
        let form = forms[i]
        form.appendChild(input.cloneNode(true))
        const submitBtn = form.querySelector('.js-submit-btn')
        if (submitBtn !== null) {
          submitBtn.disabled = false
        }
        form.classList.add('is-updated')
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('CSRF error. We reached our target server, but it returned an error')
    }
  }
  request.onerror = function () {
    // eslint-disable-next-line no-console
    console.error('CSRF error. There was a connection error of some sort')
  }
  request.send()
}
